// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("trix");
require("@rails/actiontext");

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("pickadate");
require("channels");

import "../stylesheets/application";
import "./bootstrap_custom.js";
import '../pickadate/picker'
import '../pickadate/picker.date'

import { loadStripe } from "@stripe/stripe-js";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix");
require("@rails/actiontext");

function setPickers() {
  $('input.pickadate').pickadate({
      format: 'yyyy-mm-dd',
      formatSubmit: 'yyyy-mm-dd',
      hiddenSuffix: '',
      hiddenName: true,
      close: 'Aizvērt'
    })

  $('input.date_filter').pickadate({
      format: 'yyyy-mm-dd',
      formatSubmit: 'yyyy-mm-dd',
      hiddenSuffix: '',
      hiddenName: true,
      close: 'Aizvērt'
    })
}

document.addEventListener("turbolinks:load", () => {
   setPickers()

   $(function() {
    $('[data-toggle="tooltip"]').tooltip();
  });
})
